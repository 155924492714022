import React, { useState,useEffect } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import { pre } from "../api/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Components/Loader";

const LoginRoute = () => {
  const navigate=useNavigate();
  useEffect(()=>{
    navigate("/demo");
  },[])
  // const [number, setNumber] = useState("");
  // const navigate=useNavigate();
  // const [loading,setLoading]=useState(false);

  // const submitHandler = async (e) => {
  //   e.preventDefault();
  //   if (number.trim().length > 0) {
  //     try {
  //       setLoading(true);
  //       const res = await axios.get(`${pre}/checkuser/${number}`);
  //       if(res?.data?.status==1){
  //           setLoading(false);
  //           window.location.href = `/redirect?msisdn=${number}`;
  //       }
  //       else{
  //           setLoading(false);
  //           toast.error("You are not subscribed!");
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       toast.error(error?.message);
  //     }
  //   } else {
  //     toast.error("Number cannot be empty!");
  //   }
  // };
  return (
    <>
      {/* <Navbar />
      <div className="mt-8 w-full flex justify-center">
        <div className="p-8 bg-gray-500 w-2/4 rounded-lg max-[800px]:w-3/4">
          <form className="max-w-sm mx-auto" onSubmit={submitHandler}>
            <div className="mb-5">
              <label
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Your Number
              </label>
              <input
                type="number"
                id="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="********83"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Login
            </button>
          </form>
          <div>
            {loading && <Loader />}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default LoginRoute;
