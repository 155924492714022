// const pre = `http://148.251.88.109:4080`;
const pre=``;
export { pre };

const videosApi = `videos`;
export { videosApi };

const videoByIdApi=`singlevideo`;
export {videoByIdApi};

const randomVideosApi=`random`;
export {randomVideosApi}

const imagesApi=`getimage`;
export {imagesApi};
