import React from "react";
import classes from "./Navbar.module.css";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
    const navigate=useNavigate(); 
  return (
    <nav className={classes.navbar}>
      <div className={classes.logo_container}>
        <Link>
          <img src="/assets/images/toonflix.png" alt="Logo" className={classes.image} onClick={()=>navigate("/demo")}/>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;